import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/axios";
import useSmartToast from "../../../../hooks/SmartToast";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  others
} from "@chakra-ui/react";
import { useRouter } from "next/router";

export default function VehicleTypeStats({city = null}) {
  const [stats, setStats] = useState<any>(null);
  const toast = useSmartToast();
  const router = useRouter();

  const getVehicleStats = async () => {
    try {
      let params: any = {
        city: null
      };
      if (city === "MUMBAI" || city === "PUNE") {
        params.city = city;
      }
      const { data } = await axiosClient.get("/stats/vehicle/types", {params});
      setStats(data);
    } catch (error) {
      console.log(error);
      toast.error(error, "An error occurred while fetching vehicle stats.");
    }
  };

  useEffect(() => {
    getVehicleStats();
  }, [city]);

  function statRenderer(stat: any, index: number) {
    return (
      <Stat
        border="1px"
        borderColor="gray.300"
        p="4"
        rounded="md"
        transition={"all .2s ease-in-out"}
        _hover={{
          cursor: "pointer",
          bg: "gray.50",
          borderColor: "gray.400"
        }}
        // opacity={type ? (type === stat || type === "" ? 1 : 0.5) : 1}
      >
        <StatLabel>{stat.vehicleType}</StatLabel>
        <StatNumber>{stat.count}</StatNumber>
      </Stat>
    );
  }

  return (
    <Stack>
      <SimpleGrid columns={3} spacing={2}>
        {stats &&
          stats.map((stat: any, index: number) =>
            statRenderer(stat, index)
          )}
      </SimpleGrid>
    </Stack>
  );
}
