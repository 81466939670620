import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/axios";
import useSmartToast from "../../../../hooks/SmartToast";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  others
} from "@chakra-ui/react";
import { useRouter } from "next/router";

export default function GarageStats({city}) {
  const [stats, setStats] = useState<any>(null);
  const [total, setTotal] = useState<number>(0);
  // const [pieData, setPieData] = useState<any>(null);
  // const [labels, setChartLabels] = useState<any>(null);
  const toast = useSmartToast();
  const router = useRouter();
  const garageTitles = {
    PMS: "PMS",
    accidentalDamage: "Accidental Damage",
    runningRepair: "Running Repair",
    freeService: "Free Service",
    batteryReplacement: "Battery Replacement",
    readyInGarage: "Ready in Garage",
    others: "Others",
  };

  const filter = {
    PMS: "PMS",
    accidentalDamage: "ACCIDENTAL_DAMAGE",
    runningRepair: "RUNNING_REPAIR",
    freeService: "FREE_SERVICE",
    batteryReplacement: "BATTERY_REPLACEMENT",
    others: "ANY"
  }

  const getVehicleStats = async () => {
    try {
      let params: any = {
        city: null
      };
      if (city === "MUMBAI" || city === "PUNE") {
        params.city = city;
      }
      const { data } = await axiosClient.get("/stats/garage", {params});
      setStats(data);
    } catch (error) {
      console.log(error);
      toast.error(error, "An error occurred while fetching vehicle stats.");
    }
  };

  const getLabel = (label: string) => {
    return garageTitles[label];
  };

  useEffect(() => {
    getVehicleStats();
  }, [city]);

  useEffect(() => {
    if (stats) {
      let total = 0;
      for (const key in stats) {
        if (Object.prototype.hasOwnProperty.call(stats, key)) {
          total += stats[key];
        }
      }
      setTotal(total);
    }
  }, [stats]);

  function statRenderer(stat: any, index: number) {
    return (
      <Stat
        border="1px"
        borderColor="gray.300"
        p="4"
        rounded="md"
        transition={"all .2s ease-in-out"}
        _hover={{
          cursor: "pointer",
          bg: "gray.50",
          borderColor: "gray.400"
        }}
        onClick={() => {
          if (stat === "readyInGarage") {
            router.push(`/vehicles?status=READY_IN_GARAGE&city=${city}`);
            return;
          } else {
            router.push(`/vehicles?status=IN_GARAGE&serviceType=${filter[stat]}&city=${city}`);
          }
        }}
        // opacity={type ? (type === stat || type === "" ? 1 : 0.5) : 1}
      >
        <StatLabel>{getLabel(stat)}</StatLabel>
        <StatNumber>{stats[stat]}</StatNumber>
      </Stat>
    );
  }

  return (
    <Stack>
      <HStack justify={"space-between"}>
        <Text fontSize={"2xl"} fontWeight={"bold"}>
          Garage ({total})
        </Text>
      </HStack>
      <SimpleGrid columns={4} spacing={2}>
        {stats &&
          Object.keys(garageTitles).map((stat: any, index: number) =>
            statRenderer(stat, index)
          )}
      </SimpleGrid>
    </Stack>
  );
}
